import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createFetchProduct } from '../actions/product';
import { getProduct } from '../selectors';
import { getProductSkuDropdown } from '../selectors/dropdowns';

import Loading from './Loading';
import Select from './Select';

class AxisOptionSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props.value
    };

    this.onSkusSelected = this.onSkusSelected.bind(this);
  }

  componentDidMount() {
    if (!this.props.product) {
      this.props.loadProduct();
    }
  }

  onSkusSelected() {
    const { onOptionSelected } = this.props;
    const { axis, option } = this.state;
    if (!!onOptionSelected) {
      onOptionSelected(axis, option);
    }
  }

  getOptionsFromSkus(skus) {
    const options = skus.reduce(
      (o, sku) => o.concat(sku.options.length > 0 ? sku.options : [{ option_axis: 'sku', option_name: sku.sku }]),
      []
    ).reduce(
      (o, option) => ({
        ...o,
        [option.option_axis]: Array.from(new Set((o[option.option_axis] || []).concat(option.option_name)))
      }),
      {}
    );
    return options;
  }

  getOptionsFromSizeColor(sizes, colors) {
    const options = {};
    if (sizes.length > 0) {
      options.size = sizes.map(s => s.size_name);
    }
    if (colors.length > 0) {
      options.color = colors.map(c => c.color_name);
    }
    return options;
  }

  getOptions() {
    const { product, skus, sizes, colors } = this.props;
    if ('ps-products' === product.copied_from) {
      return this.getOptionsFromSkus(skus);
    }
    return this.getOptionsFromSizeColor(sizes, colors);
  }

  render() {
    const { product, skus } = this.props;
    const { axis, option } = this.state;

    if (!product) {
      return <Loading />;
    }

    const options = this.getOptions();
    const hasSizeAxis = Object.keys(options).includes('size');
    const axes = Object.keys(options).filter(a => 'dimension' !== a || (options[a].length > 1 && !hasSizeAxis)).sort();
    if (axes.length === 0) {
      <div>
        No relevant axes for up charges.
      </div>;
    }

    const listStyle = {
      float: 'left',
      clear: 'both',
      marginLeft: '0.25rem',
      listStyleType: 'none'
    };
    const listItemStyle = {
      float: 'left',
      clear: 'both',
    };

    const textTransform = 'color' === axis ? 'capitalize' :  ('size' === axis ? 'uppercase' : undefined );

    return (
      <div>
        <Select
          style={{ textTransform: 'capitalize' }}
          options={axes.map(a => ({ key: a, value: a }))}
          value={axis}
          change={axis => this.setState({ axis, option: null })}
          withMarginBottom
        />
        {!!axis && (
          options[axis].length > 10 ?
            <Select
              options={options[axis].sort().map(o => ({ key: o, value: o }))}
              value={option}
              change={option => this.setState({ option }, () => this.props.onOptionSelected(axis, option))}
              style={{ textTransform }}
              withMarginBottom
            /> :
            <ul style={listStyle}>
              {options[axis].map(o =>
                <li key={o} onClick={() => this.setState({ option: o }, () => this.props.onOptionSelected(axis, o))}>
                  <input
                    type="radio"
                    name={`option-${o}`}
                    readOnly={true}
                    checked={option === o}
                    style={{ marginRight: '0.5rem', textTransform }}
                  />
                  {o}
                </li>
              )}
            </ul>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  product: getProduct(state, ownProps),
  skus: getProductSkuDropdown(state, ownProps),
  colors: Array.from(new Set((state.dropdowns.product_colors[ownProps.product_id] || []).concat(
    ownProps.item.item_colors.map(c => c.color_id)
  ).filter(
    c => c !== 'TBD'
  ).map(
    c => state.entities.colors[c]
  ))).filter(
    c => !!c
  ).sort(
    (a, b) => a.color_name < b.color_name ? -1 : (a.color_name > b.color_name ? 1 : 0)
  ),
  sizes: Array.from(new Set((state.dropdowns.product_sizes[ownProps.product_id] || []).concat(
    ownProps.item.item_sizes.map(s => s.size_id)
  ).filter(
    s => s !== 'TBD'
  ))).map(
    s => state.entities.sizes[s]
  ).filter(
    s => !!s
  )
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadProduct: () => dispatch(createFetchProduct(ownProps.product_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AxisOptionSelector);
