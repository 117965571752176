import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import { createAddItemRetailAdjustment, createUpdate } from '../actions';
import * as actions from '../actions/item';
import { getBaseItem } from '../selectors';

import SelectSku from './SelectSku';
import AxisOptionSelector from './AxisOptionSelector';

const updateItemRetailAdjustment = createUpdate('item-retail-adjustment', {
  request: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST,
  success: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_SUCCESS,
  failure: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_FAILURE,
  failure_message: 'Unable to update retail adjustment'
});

class SelectSkuOrAxisOptionPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      sku_loaded: false,
      axis_loaded: false,
      selector: props.selector || 'axis'
    };
  }

  render() {
    const { product_id, item, item_retail_adjustment_id, index, onClosePopup, onAddRetailAdjustment, onUpdateRetailAdjustment } = this.props;
    const { axis_loaded, sku_loaded, selector, value } = this.state;

    const isUpdate = !!item_retail_adjustment_id;

    return (
      <div className="reveal large" style={{ overflowY: 'visible', display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial' }} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>Select SKU or Option</h3>
            <p>Please select the SKU or option you wish to use.</p>
          </div>
        </div>
        <div className="row full-width">
          {'ps-products' === item.copied_from && <div className="small-6 columns" onClick={() => this.setState({ selector: 'axis', value: null })}>
            <input type="radio" readOnly={true} checked={selector === 'axis'} style={{ marginRight: '0.5rem' }} />
            Choose by Option
          </div>}
          {'ps-products' === item.copied_from && <div className="small-6 columns" onClick={() => this.setState({ selector: 'sku', value: null })}>
            <input type="radio" readOnly={true} checked={selector === 'sku'} style={{ marginRight: '0.5rem' }} />
            Choose by SKU
          </div>}
          <div className="small-12 columns">
            {'sku' === selector ?
            <SelectSku
              product_id={product_id}
              value={[value]}
              onSkusSelected={value => this.setState({ value: { sku: value[0] } })}
            /> :
            <AxisOptionSelector
              product_id={product_id}
              item={item}
              onLoaded={() => this.setState({ axis_loaded: true })}
              onOptionSelected={(axis, option) => this.setState({ value: { axis, option } })}
              value={value}
            />}
          </div>
          <button disabled={!value} className="button" style={{ margin: 0, clear: 'both', float: 'right' }} onClick={isUpdate ? () => onUpdateRetailAdjustment(selector, value) : () => onAddRetailAdjustment(selector, value)}>{!!item_retail_adjustment_id ? 'Update' : 'Add'}</button>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  item: getBaseItem(state, ownProps)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onAddRetailAdjustment: (selector, value) => {
    dispatch(createAddItemRetailAdjustment(ownProps.item_id, selector, value));
    dispatch(closePopup());
  },
  onUpdateRetailAdjustment: (selector, value) => {
    dispatch(updateItemRetailAdjustment(ownProps.item_retail_adjustment_id, selector, null, value));
    dispatch(closePopup());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSkuOrAxisOptionPopup);
